import gql from "graphql-tag";

export default gql`
  query getBasicSiteSettingBySite($domain: String!) {
    getBasicSiteSettingBySite(domain: $domain) {
      siteLogo {
        bucket
        region
        key
      }
      favIcon {
        bucket
        region
        key
      }
      transitionImage {
        bucket
        region
        key
      }
      festivalBanners
      festivalSubBanner {
        bucket
        region
        key
      }
      siteImages {
        bucket
        region
        key
      }
      isWebAppEnabled
      mapLink
      addressOne
      addressTwo
      menuDesign
      headerDesign
      headerDesignWeb
      siteModeStaticPage
      headerDesignMob
      footerDesign
      footerDesignWeb
      footerDesignMob
      homePageTitle
      homePageMetadesc
      companyName
      plpDisplay
      showInventory
      backgroundColor
      menuFontColor
      textFontColor
      labelFontColor
      contactNumber
      additionalContactNumbers
      companyProfile
      contactEmail
      vendorStatus
      landingPage
      integratePaymentOption
      checkoutButtonColor
      siteOptions
      textFontType
      regions
      supportNumber
      paymentModes{
        internalName
        displayName
        paymentState
      }
      footerAddress {
        addressLineOne
        addressLineTwo
        city
        state
        zip
        country
        location{
          lat
          long
        }
      }
      latlng {
        lat
        lng
      }
      whatsAppSeller
      whatsAppBuyer
      emailAppBuyer
      feedBackToBuyer
      inventory
      diningSetup
      themeDesign
      frontSite
      isCustomWhatsapp
      diningSettingMenu
      siteMode
      invoice
      availableWhatsApp
      SpeechSearch
      digitalCard
      appVersion
      isWalletEnabled
      curr
      isDayClosureEnabled
      isCounterEnabled
      isReturnOrderEnabled
      dashboardRangeEnabled
      newOrderAppEnabled
      htmlPrint
      fssai
      gstNo
    }
  }
`;
